import React, { FC, Children, PropsWithChildren } from 'react'
import {
  CarouselState,
  CarouselRoot,
  CarouselControls,
  UnstyledCarouselScrollArea,
  CarouselPreviousButton,
  CarouselNextButton,
  CarouselItem,
} from '@moonpig/launchpad-components'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

export type Props = {
  hideScrollButtons?: boolean
}

const StyledCarouselItem = styled(CarouselItem)`
  &:first-child {
    ${s({ ml: { xs: 5, md: 0 } })}
  }

  &:last-child {
    ${s({ mr: { xs: 5, md: 0 } })}
  }
`

const CarouselScrollArea = styled(UnstyledCarouselScrollArea)<{
  centerSlides?: boolean
}>`
  ${s({
    gap: { xs: 12, md: 16 },
  })}

  ${breakpointUp('md')} {
    justify-content: ${({ centerSlides }) =>
      centerSlides ? 'center' : 'initial'};
  }
`

const StyledPreviousButton = styled(CarouselPreviousButton)`
  ${breakpointDown('md')} {
    display: none;
  }
`

const StyledNextButton = styled(CarouselNextButton)`
  ${breakpointDown('md')} {
    display: none;
  }
`

const StyledCarouselRoot = styled(CarouselRoot)`
  margin-left: -16px;
  margin-right: -16px;
  ${breakpointDown('md')} {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

export const Carousel: FC<PropsWithChildren<Props>> = ({
  hideScrollButtons,
  children,
}) => {
  return (
    <CarouselState>
      <StyledCarouselRoot>
        <CarouselControls>
          {!hideScrollButtons && <StyledPreviousButton />}
          {!hideScrollButtons && <StyledNextButton />}
        </CarouselControls>
        <CarouselScrollArea
          centerSlides={hideScrollButtons}
          data-testid="lp-components-carousel-scroll-area"
        >
          {Children.map(children, (item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledCarouselItem tabIndex={undefined} key={index}>
              {item}
            </StyledCarouselItem>
          ))}
        </CarouselScrollArea>
      </StyledCarouselRoot>
    </CarouselState>
  )
}
